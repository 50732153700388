<template>
    <div class="row row align-items-start bg-background-ecommerce vh-100">
        <div class="w-100 d-flex justify-content-center flex-column mt-4">
            <div class="d-flex justify-content-center mb-4">
                <img :src="vacinaSesiLogo" alt="logo VacinaSESI">
            </div>

            <h3 class="text-text-dark text-center mb-1">Lançamento em breve!</h3>
            <span class="text-text-dark text-center">Nosso e-commerce para você e sua família tem previsão de lançamento no segundo semestre de 2022.</span>
            <span class="text-text-dark text-center">Fique atento e acompanhe nossas novidades!</span>
            <div class="d-flex justify-content-center">
                <b-button variant="flat-dark" class="mt-2" :to="{ name: 'start-page' }">
                    <feather-icon
                        icon="ArrowLeftIcon"
                        class="mr-50"
                    />
                    <span class="align-middle">Voltar para página principal</span>
                </b-button>
            </div>
        </div>

        <div class="fixed-bottom mx-auto image-people align-items-end w-100">
            <img class="w-100" :src="peopleVaccinated" alt="Pessoas felizes vacinadas no ombro teste">
        </div>
        <footer class="fixed-bottom d-flex justify-content-center bg-custom-blue pt-2 pb-2 w-100">
            <div class="row">
                <div class="col-12 mb-2">
                    <span class="text-white">Realização:</span>

                </div>
                <div class="col-12 image-logo">
                    <img class="w-100" :src="logoSesi" alt="logo do realizador SESI - Serviço Social da Indústria">
                </div>
            </div>
        </footer>
    </div>
</template>

<script>
import peopleVaccinated from '@/assets/custom-images/peopleVaccinated.png';
import vacinaSesiLogo from '@/assets/custom-images/vacina-sesi-logo-azul.svg';
import logoSesi from '@/assets/custom-images/logo-SESI.png';

import { BButton } from 'bootstrap-vue';

export default {
    title: 'Em breve',

    components: {
        BButton
    },

    mounted() {
        this.$helpers.indexablePage();
    },

    data() {
        return {
            peopleVaccinated,
            vacinaSesiLogo,
            logoSesi
        }
    }
}

</script>

<style scoped>
    .image-logo {
        width: 4rem;
    }

    .image-people {
        margin-bottom: 148px;
        max-width: 1950px;
    }

    @media only screen and (min-width: 1624px) {
        .image-people {
            margin-bottom: 10px;
        }
    }

    @media only screen and (max-height: 915px) {
        .image-people {
            display: none;
        }
    }
</style>
